<template>
    <div>
        <modal :name="modal_name" class="final-modal" transition="nice-modal-fade" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer'}">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title text-white" v-if="product.is_combo">ADD / EDIT {{product.product_name}}</span>
                    <span class="v-modal-dialog-title v-modal-title text-white" v-else>ADD CUSTOMIZATION</span>
                    <span @click="hideAddCustom()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0 pt-0 pl-0" style="max-height: 330px;overflow-x: hidden;overflow-y: auto;">
                    <div class="v-modal-layout">
                        <div class="">
                            <div class="">
                                <div class="p-4 customize">
                                    <div class="d-flex align-items-center newBox" :class="{'justify-content-center':product.is_combo ,'justify-content-between':!product.is_combo }">
                                        <span class="d-flex" v-if="!product.is_combo">
                                            <el-tooltip v-if="product.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="width: 12px;" />
                                            </el-tooltip>
                                            <el-tooltip v-else-if="product.product_type == 'Veg'" content="Veg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="width: 12px;" />
                                            </el-tooltip>
                                            <el-tooltip v-else-if="product.product_type == 'Egg'" content="Egg" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="width: 12px;" />
                                            </el-tooltip>
                                            <el-tooltip v-else-if="product.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                <img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                            </el-tooltip>
                                            <span class="text-secondary font-weight-bold" :class="{'pl-2':product.product_type != ''}">{{product.product_name}}</span>
                                        </span>
                                        <button class="btn btn-secondary btn-smm btn-smm-radius text-truncate" :title="'+ Add New Customization'" @click="addNewCustomization()" type="button">+ Add New Customization</button>
                                    </div>
                                </div>
                                <div class="p-4 customize">
                                    <template v-for="(custom,customize_index) in customization">
                                        <div :key="customize_index" v-loading="increase_decrease_in_progress" class="d-flex justify-content-between align-items-center mt-2 newBox flex-wrap">
                                            <div class="w-33-100 mb-3">
                                                <div class="">
                                                    <span class="d-flex">
                                                        <el-tooltip v-if="product.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="product.product_type == 'Veg'" content="Veg" placement="top">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="product.product_type == 'Egg'" content="Egg" placement="top">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="product.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                            <img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                        </el-tooltip>
                                                        <span class="font-weight-bold" :class="{'pl-2':product.product_type != ''}">{{ custom.variant_name || product.product_name }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="w-33 text-center">
                                                <span class="font-weight-bold">Total Price : {{custom.total}}</span>
                                            </div>
                                            <div class="input-prepend-append d-flex pl-1 pr-1 align-items-center justify-content-end w-33-25">
                                                <a class="btn-prepend pt-1 pointer" @click="decreaseCustomQty(custom,customize_index)">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg" class="w-25-20px" />
                                                </a>
                                                <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" @change="enterQuantity(custom,customize_index)" v-model.number="custom.quantity" class="form-control text-center add-custom-addon"/>
                                                <a class="btn-append pt-1 pointer" @click="increaseCustomQty(custom,customize_index)">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/plus-blue.svg" class="w-25-20px" />
                                                </a>
                                            </div>
                                        </div>
                                        <!-- <div class="d-flex align-items-center flex-wrap mt-1" :key="customize_index">
                                            <span class="text-green mt-1 font-weight-bold" v-if="custom.addons && custom.addons.length">Add-On</span>
                                            <div class="d-flex mt-1 ml-2" v-for="(addon,addon_index) in custom.addons" :key="addon_index">
                                                <el-tooltip v-if="addon.type_name == 'Non-Veg'" content="Non-Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="addon.type_name == 'Veg'" content="Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="addon.type_name == 'Egg'" content="Egg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="addon.type_name == 'Pescatarian'" content="Pescatarian" placement="top">
                                                    <img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                </el-tooltip>
                                                <span class="font-weight-bold" :class="{'ml-2':addon.type_name != ''}">
                                                    {{addon.name}}
                                                    <span class="text-green ml-1 font-weight-bold"
                                                    >X {{addon.quantity}}</span>
                                                    <span class="text-green ml-1 font-weight-bold" v-if="addon_index != custom.addons.length - 1">,</span>
                                                </span>
                                            </div>
                                        </div> -->
                                        <div class="d-flex align-items-center flex-wrap mt-1" :key="customize_index">
                                            <span class="text-green mt-1 font-weight-bold" v-if="custom.combo_items && custom.combo_items.length">Combination:</span>
                                            <div class="d-flex mt-1 ml-2" v-for="(combo,combo_index) in custom.combo_items" :key="combo_index">
                                                <el-tooltip v-if="combo.variant_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="combo.variant_type == 'Veg'" content="Veg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="combo.variant_type == 'Egg'" content="Egg" placement="top">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                </el-tooltip>
                                                <el-tooltip v-else-if="combo.variant_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                                    <img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                </el-tooltip>
                                                <span class="font-weight-bold" :class="{'ml-2':combo.variant_type != ''}">
                                                    {{combo.product_name}}
                                                    <span class="text-green ml-1 font-weight-bold">X {{combo.quantity}}</span>
                                                    <span class="text-green ml-1 font-weight-bold" v-if="combo_index != custom.combo_items.length - 1">,</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-start flex-wrap mt-1" :key="customize_index">
                                            <span class="text-green mt-1 font-weight-bold" v-if="custom.modifiers && (custom.modifiers.for_primary || custom.modifiers.for_secondary)">Modifiers:</span>
                                            <div v-if="custom.modifiers.for_secondary">
                                                <div class="d-flex mt-1 ml-2" v-for="(modifier_secondary,modifier_secondary_index) in custom.modifiers.for_secondary" :key="modifier_secondary_index">
                                                    <span class="ml-2">
                                                        <span class="text-secondary font-weight-bold">{{modifier_secondary.m_name}}</span>
                                                        <span class="ml-2">{{modifier_secondary.m_item_name}}</span>
                                                        <span v-if="modifier_secondary.quantity>0" class="ml-1 font-weight-bold">X {{modifier_secondary.quantity}}</span>
                                                        <!-- <span class="text-green ml-1 font-weight-bold" v-if="combo_index != custom.combo_items.length - 1">,</span> -->
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-if="custom.modifiers.for_primary">
                                                <div class="d-flex mt-1 ml-2" v-for="(modifier_primary,modifier_primary_index) in custom.modifiers.for_primary" :key="modifier_primary_index">
                                                    <span class="ml-2">
                                                        <span class="text-secondary font-weight-bold">{{modifier_primary.modifier_name}}</span>
                                                        <span v-for="(modifier_primary_item, mod_item_index) in modifier_primary.modifier_items" :key="mod_item_index+'mod_index'" class="ml-2">
                                                            {{modifier_primary_item.m_item_name}}
                                                            <span v-if="modifier_primary_item.quantity>0" class="ml-1 font-weight-bold">X {{modifier_primary_item.quantity}}</span>
                                                            <!-- <span class="text-green ml-1 font-weight-bold" v-if="combo_index != custom.combo_items.length - 1">,</span> -->
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="v-modal-dialog-actions modal-bg" style="border-radius: 0px 0px 15px 15px;">
                            <a class="btn btn-link btn-smm" @click="hideAddCustom()">CANCEL</a>
                            <button class="btn btn-secondary btn-smm text-uppercase" @click="saveCustom()">SAVE</button>
                </div>-->
            </div>
        </modal>
    </div>
</template>
<script>
export default {
    props: ['modal_name', 'product', 'order_index', 'selectedCourse'],
    data() {
        return {
            customization: [],
            addons: [],
            safariTrue:false,
            isTablet: false,
            increase_decrease_in_progress: false
        }
    },
    methods: {
        hideAddCustom() {
            setTimeout(() => {
                this.$emit('hideAddCustom')
            }, 500)
        },
        saveCustom() {
            this.$emit('saveAddCustom')
        },
        async getCustomizations() {
            let params = {
                product_id: this.product.product_id,
                order_id: this.order_index,
                course: this.selectedCourse
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('mobile/get_customizations_for_product',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async loadCustomizations(params) {
            let response = await this.getCustomizations(params)
            this.customization = response.customizations
            if (this.customization.length) {
                this.addons = response.customizations[0].addons
            }
            if (this.customization.length == 0) {
                this.hideAddCustom()
            }
        },
        async enterQuantity(custom, custom_index) {
            this.increase_decrease_in_progress = true;
            if (this.customization[custom_index].quantity <= 0) {
                this.customization[custom_index].quantity = 0
            }
            if (!this.product.hasVariant && this.customization[custom_index].quantity > this.productQty) {
                this.customization[custom_index].quantity = this.productQty
            } else if (this.product.hasVariant && this.customization[custom_index].quantity > this.product.variant_quantity + this.product.selected_quantity) {
                this.customization[custom_index].quantity = this.variantQty
            }
            let params = {
                item_id: this.customization[custom_index]._id,
                quantity: this.customization[custom_index].quantity,
                variant_id: this.customization[custom_index].variant_id,
                addons: this.customization[custom_index].addons,
                is_customizable: this.product.is_customisable
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/mobile/orders/items/edit',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                    this.increase_decrease_in_progress = false;
                    this.loadCustomizations()
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async increaseCustomQty(custom, custom_index) {
            this.increase_decrease_in_progress = true;
            this.customization[custom_index].quantity++
            if (!this.product.hasVariant && this.customization[custom_index].quantity > this.productQty) {
                this.customization[custom_index].quantity = this.productQty
            } else if (this.product.hasVariant && this.customization[custom_index].quantity > this.product.variant_quantity + this.product.selected_quantity) {
                this.customization[custom_index].quantity = this.variantQty
            }
            let params = {
                item_id: this.customization[custom_index]._id,
                quantity: this.customization[custom_index].quantity,
                variant_id: this.customization[custom_index].variant_id,
                addons: this.customization[custom_index].addons,
                is_customizable: this.product.is_customisable
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/mobile/orders/items/edit',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                    this.increase_decrease_in_progress = false;
                    this.loadCustomizations()
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async decreaseCustomQty(custom, custom_index) {
            this.increase_decrease_in_progress = true;
            this.customization[custom_index].quantity--
            if (this.customization[custom_index].quantity <= 0) {
                this.customization[custom_index].quantity = 0
            }
            let params = {
                item_id: this.customization[custom_index]._id,
                quantity: this.customization[custom_index].quantity,
                variant_id: this.customization[custom_index].variant_id,
                addons: this.customization[custom_index].addons,
                is_customizable: this.product.is_customisable
            }
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/mobile/orders/items/edit',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                    this.increase_decrease_in_progress = false;
                    this.loadCustomizations()
                } else {
                    reject(response.data.reason)
                }
            })
        },
        addNewCustomization() {
            this.$emit('add_new_cusomization', this.product)
            this.$modal.hide(this.modal_name)
        }
    },
    created() {
        this.loadCustomizations()
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser
        },
        customPrice() {
            let res = null
            let string = ''
            this.customization.forEach((custom, index) => {
                string = custom.total.replace(',', '')
            })
            return (res = parseFloat(string.slice(1, string.length)))
        },
        productQty() {
            return this.product.variant_quantity + this.product.selected_quantity
        },
        variantQty() {
            return this.product.variant_quantity + this.product.selected_quantity
        }
    },
    mounted(){
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
}
</script>
<style scoped>
.customize:nth-of-type {
    background: #eff1f4;
}
.w-33 {
    width: 33.333%;
}
.w-33-25 {
    width: 33.333%;
}

.w-33-100 {
    width: 33.333%;
}

.w-25-20px {
    width: 25px;
}
.add-custom-addon {
    min-width: 36px !important;
    width: 45px !important;
    border-bottom: none !important;
    color: #00448b !important;
}
.v-modal-layout {
    overflow: initial !important;
}
.btn-smm-radius {
    border-radius: 5px !important
}
.final-modal .v-modal-header {
    background-color: #00448b !important;
}

.final-modal .v-modal-title {
    color: #fff !important;
}
.newBox {
    box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #00448b;
}
.bgGrey {
    background-color: #eff1f4;
    padding: 5px;
    border-radius: 8px;
}
.final-modal .v-modal-body{
    background-color :#eff1f4 !important;
}
.w-200{
    width: 200px
}

@media screen and (max-width: 500px) {
    .w-33-100 {
        width: 100%;
    }
}

@media (max-width: 350px) {
    .add-custom-addon {
        /* width: 20px !important; */
        border-bottom: none !important;
        color: #00448b !important;
    }
    .w-33-25 {
        width: 25.333%;
    }
    .w-33 {
        width: 37.333%;
    }
    .w-25-20px {
        width: 20px;
    }
}
</style>